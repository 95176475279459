body, html {
  background-color: rgb(196, 196, 196);
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.erp {
  background-color: rgb(196, 196, 196); /* Ensure entire app area is gray */
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-container {
  background-color: rgb(196, 196, 196);
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 70px; /* Fallback margin if menu is not expanded */
  overflow-y: auto; /* Allow content scrolling only when necessary */
}

.content-container {
  flex-grow: 1;
  padding: 20px;
  background-color: rgb(196, 196, 196);
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--navbar-height)); /* Ensure it takes up full viewport height minus navbar */
  overflow-y: auto; /* Allow scrolling within content if needed */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.modal1 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 3000;
  width:1000px;
  max-height: 90%;
  overflow-y: auto;
  margin: 0 auto;
}

.modal2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 3000;
  width:1000px;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000; /* Higher z-index to ensure it's on top of everything else */
}

.menu-container {
  position: fixed;
  top: calc(var(--navbar-height)); /* Adjust to start below the navbar */
  left: 0;
  height: calc(100vh - var(--navbar-height)); /* Full height minus navbar */
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: width 0.3s;
  z-index: 1050;
  display: flex;
  flex-direction: column;
}

.list-group {
  flex-grow: 1; /* Allows the list to grow and take available space */
  overflow-y: auto; /* Allows scrolling within the menu */
}

.menu-container .offcanvas-header {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.menu-container .list-group-item {
  display: flex;
  align-items: center;
}

.menu-container .list-group-item .me-2 {
  font-size: 1.2em;
}

.menu-container .menu-label {
  display: none;
}

.menu-container:hover .menu-label {
  display: inline;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

.navbar-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  white-space: nowrap;
}

.navbar-nav .nav-item {
  flex: 0 1 auto;
  margin-left: 1rem;
  text-align: center;
}

.navbar-nav .nav-link {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nav-profile {
  display: flex;
  align-items: center;
}

.dropdown-menu {
  position: absolute;
  background-color: black;
}

.accordion-button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #000;
  font-weight: normal;
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #e9ecef;
}

.accordion-body {
  padding: 0;
}

.accordion-body .dropdown-item {
  padding-left: 1.5rem;
}

.logout-container {
  padding: 1rem;
  background-color: white; /* Ensures the logout area is visually distinct */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for emphasis */
  text-align: center;
}

.user-info {
  margin-bottom: 10px;
  text-align: center;
}

.logout-button {
  width: 100%;
  text-align: center;
}

.title-success {
  color: green;
}

.title-danger {
  color: red;
}

.alerta {
  position: fixed;
  z-index: 10000; /* Asegúrate de que el z-index sea mayor que modal1 y modal2 */
}

.badge {
  font-size: 0.9rem;
  padding: 0.4em 0.6e
}